import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecentShippings } from './recentShippingsLogic';

export const RecentShippings: React.FC<{ className: string; mode?: 'exposed'; merchantColor?: string }> = ({
  className,
  mode,
  merchantColor
}) => {
  const { t } = useTranslation();
  const shippings = useRecentShippings();

  if (!shippings || shippings.length === 0) {
    return null;
  }

  return (
    <div className={classNames('w-full', className)}>
      <p className={`px-4  ${mode !== 'exposed' ? 'md:hidden' : ''}  pb-2 text-gray-500`}>
        {t('tracking_page.recent_shipments.recent_searches')}
      </p>

      <div
        className={`bg-white md:rounded-lg md:border md:border-gray-200 ${
          mode !== 'exposed' ? `md:shadow-lg` : ''
        } overflow-hidden`}
      >
        <p className={`hidden ${mode !== 'exposed' ? 'md:block' : ''} px-6 pt-4 pb-2 text-gray-500`}>
          {t('tracking_page.recent_shipments.recent_searches')}
        </p>

        <ul className="divide-y divide-gray-200">
          {shippings.map(({ sender, recipient, trackingCode /*, lastSeen*/ } /*, i*/) => (
            <li key={trackingCode}>
              <Link
                onMouseDown={(e) => {
                  // this prevents the blur on the input that would
                  // hide the link before it'd receive the click
                  e.preventDefault();
                }}
                to={`/tracking/${trackingCode}`}
                className="block hover:bg-gray-50"
              >
                <div className={'px-4 py-4 flex items-center sm:px-6'}>
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <div className="flex text-sm">
                        <p
                          className={`font-medium ${!merchantColor ? 'text-blue-600' : ''}`}
                          style={{ color: merchantColor }}
                        >
                          {sender} <span className="text-gray-400"> → </span>
                          {recipient}
                        </p>
                      </div>
                      <div className="mt-2 flex">
                        <div className="flex items-center text-sm text-gray-500">
                          {/* TODO: change */}
                          <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <p>{trackingCode}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 flex-shrink-0">
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
