import React from 'react';
import { RecentShippings } from '../recentShippings';
import { HeroBackgroundPattern } from './heroBackgroundPattern';
import { SearchInput } from './searchInput';

const Hero = ({
  heroTitleBlue,
  heroTitleBlack,
  inputPlaceHolder,
  searchButtonText,
  whereDoIFindTheTrackingCode
}: {
  heroTitleBlack: string;
  heroTitleBlue: string;
  inputPlaceHolder: string;
  searchButtonText: string;
  whereDoIFindTheTrackingCode: string;
}) => (
  <div className="relative z-10">
    <HeroBackgroundPattern />

    <div className="relative pb-16 sm:pb-24">
      <main className="mt-10 md:mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">{heroTitleBlack} </span>
            <span className="block text-blue-600 xl:inline">{heroTitleBlue}</span>
          </h1>
        </div>
        <div className="flex flex-row justify-center py-10 md:py-20">
          <SearchInput inputPlaceHolder={inputPlaceHolder} searchButtonText={searchButtonText} />
        </div>
        <div className="flex flex-row justify-center">
          <a href="#dove-trovo-il-codice-di-tracking" className="underline">
            {whereDoIFindTheTrackingCode}
          </a>
        </div>
      </main>

      <RecentShippings className="mt-10 md:hidden" />
    </div>
  </div>
);

export { Hero };
