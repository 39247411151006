import React from 'react';
import { Button } from '../../../components/button';
import { ContentContainer } from '../../../components/contentContainer';

export const NeedHelp: React.FC<{ text: string; buttonText: string }> = ({ text, buttonText }) => (
  <ContentContainer>
    <div className="py-16">
      <div className="mx-auto max-w-lg">
        <p className="text text-gray-700 mb-8">{text}</p>
        {/* TODO: connect intercom */}
        <Button color="blue" secondary>
          {buttonText}
        </Button>
      </div>
    </div>
  </ContentContainer>
);
