import classNames from 'classnames';
import { TColors, TDimensions } from './types';

export const dimensionClasses = {
  xs:
    'inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2',
  sm:
    'inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2',
  md:
    'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2',
  lg:
    'inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2',
  xl:
    'inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2'
};

export const colorClasses = {
  green: {
    primary: 'text-white bg-green-600 hover:bg-green-700 focus:ring-green-500',
    secondary: '' // TODO ?
  },
  blue: {
    primary: 'text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-400',
    secondary: 'text-blue-700 bg-blue-100 hover:bg-blue-200 focus:ring-blue-300'
  },
  orange: {
    primary: 'text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400',
    secondary: '' // TODO ?
  }
};

export const getButtonClass = (
  color: TColors,
  dimension: TDimensions | undefined,
  secondary: boolean | undefined,
  className: string | undefined
) => {
  const defaultPrimayColor: TColors = 'green';
  const defaultDimension: TDimensions = 'md';
  const weight = secondary ? 'secondary' : 'primary';

  return classNames(
    dimensionClasses[dimension || defaultDimension],
    colorClasses[color || defaultPrimayColor][weight],
    className
  );
};
