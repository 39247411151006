import React from 'react';

// <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//   <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">{children}</div>
// </div>
export const ContentContainer: React.FC = ({ children }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">{children}</div>
    </div>
  );
};
