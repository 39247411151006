/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export const useIntercom = (enabled = true) => {
  useEffect(() => {
    (window as any).Intercom('update', {
      hide_default_launcher: !enabled
    });
  }, [enabled]);

  // hides intercom on onmount
  useEffect(
    () => () => {
      (window as any).Intercom('update', {
        hide_default_launcher: true
      });
    },
    []
  );
};
