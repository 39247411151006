import React, { useState } from 'react';
import { Button } from '../../../components/button';
import { navigate } from 'gatsby';
import { RecentShippings } from '../recentShippings';
import classNames from 'classnames';

export const SearchInput: React.FC<{
  inputPlaceHolder: string;
  searchButtonText: string;
  mode?: 'exposed';
  merchantColor?: string;
}> = ({ inputPlaceHolder, searchButtonText, mode, merchantColor }) => {
  const [trackingCode, setTrackingCode] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const visibilityClassNames = isFocused ? 'hidden md:block' : 'hidden';

  const merchantColorStyleProp = merchantColor ? { style: { backgroundColor: merchantColor, color: '#ffffff' } } : {};

  return (
    <form
      className={`w-full ${
        mode !== 'exposed'
          ? 'md:w-auto md:shadow-lg md:bg-white md:rounded-lg space-y-4 md:space-y-0 md:space-x-4 md:p-8 flex flex-col md:flex-row'
          : 'my-6'
      }`}
      onSubmit={(e) => {
        e.preventDefault();
        navigate(`/tracking/${trackingCode}`);
      }}
    >
      <div className="relative">
        <input
          type="text"
          className={`${mode !== 'exposed' ? 'md:w-96' : 'py-3 mb-3'} rounded-md border-gray-300 w-full `}
          placeholder={inputPlaceHolder}
          value={trackingCode}
          onInput={(e) => setTrackingCode(e.currentTarget.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {mode !== 'exposed' ? <RecentShippings className={classNames('absolute mt-1', visibilityClassNames)} /> : null}
      </div>
      <Button
        dimension={mode !== 'exposed' ? 'lg' : 'xl'}
        className={mode !== 'exposed' ? '' : 'w-full'}
        type="submit"
        {...merchantColorStyleProp}
      >
        <span className="mx-auto">{searchButtonText}</span>
      </Button>
    </form>
  );
};
