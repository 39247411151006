import classNames from 'classnames';
import { getImage } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { ContentContainer } from '../../../components/contentContainer';

interface ICheckYourShippingBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  image: any;
}

export const CheckYourShippingBlock: React.FC<ICheckYourShippingBlockProps> = ({ title, text, image, ...divProps }) => (
  <div {...divProps} className={classNames('bg-blue-600 py-24', divProps.className)}>
    <ContentContainer>
      <div className="flex flex-col items-center">
        <h1 className="title max-w-lg text-center text-white tracking-tight mb-4">{title}</h1>
        <p className="text max-w-3xl text-center text-blue-50 mb-24">{text}</p>
        {/* @ts-expect-error TODO */}
        <GatsbyImage image={getImage(image)} alt="destinatario destinazione" />
      </div>
    </ContentContainer>
  </div>
);
