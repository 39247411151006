import { useEffect, useState } from 'react';
import { compareDesc, subDays, isAfter } from 'date-fns';
import { parseDate } from '../../../utils/parseDate';

export type TRecentShipping = {
  sender: string;
  recipient: string;
  trackingCode: string;
  lastSeen?: Date;
};

const getCleanedRecentShippings = (): TRecentShipping[] => {
  const raw = localStorage.getItem('recentShippings');
  // @ts-expect-error TOFIX
  const shippings: Map<string, TRecentShipping> = JSON.parse(raw) || {};

  const twoWeeksAgo = subDays(new Date(), 14);

  return Object.values(shippings)
    .map(({ lastSeen, ...shipping }) => ({ ...shipping, lastSeen: parseDate(lastSeen) })) // convert date from string to actual Date object
    .sort((a, b) => compareDesc(a.lastSeen, b.lastSeen)) // sort from recents to oldest
    .filter(({ lastSeen }) => isAfter(lastSeen, twoWeeksAgo)); //filter old shippings
};

export const upsertRecentShipping = (shipping: TRecentShipping) => {
  const shippingsMap = getCleanedRecentShippings().reduce(
    (acc, shipping) => ({ ...acc, [shipping.trackingCode]: shipping }),
    {}
  );

  shippingsMap[shipping.trackingCode] = { ...shipping, lastSeen: new Date() };

  localStorage.setItem('recentShippings', JSON.stringify(shippingsMap));
};

export const useRecentShippings = () => {
  const [data, setData] = useState<TRecentShipping[]>([]);

  useEffect(() => {
    setData(getCleanedRecentShippings());
  }, []);

  return data;
};
