import { isValid, parseJSON } from 'date-fns';

export const parseDate = (raw: string) => {
  const attempt = parseJSON(raw);

  if (isValid(attempt)) {
    return attempt;
  }

  return new Date(raw);
};
