import { graphql } from 'gatsby';
import React from 'react';
import { DefaultTrackingMetadata } from '../features/helmetMetadata';
import { Header } from '../molecules/header';
import { CheckYourShippingBlock } from '../molecules/index/checkYourShippingBlock';
import { HelpGrid } from '../molecules/index/helpGripd';
import { Hero } from '../molecules/index/hero';
import { NeedHelp } from '../molecules/index/needHelp/needHelp';
import { Layout } from '../molecules/layout';
import { useIntercom } from '../useIntercom';

const IndexPage = ({ data }) => {
  useIntercom();

  const { content } = data;

  return (
    <Layout header={<Header className="bg-white" />}>
      <div className="bg-white">
        <DefaultTrackingMetadata />
        <Hero
          heroTitleBlack={content.heroTitleBlack}
          heroTitleBlue={content.heroTitleBlue}
          inputPlaceHolder={content.inputPlaceHolder}
          searchButtonText={content.searchButtonText}
          whereDoIFindTheTrackingCode={content.whereDoIFindTheTrackingCode}
        />
        <HelpGrid
          block1Title={content.block1Title}
          block1Text={content.block1Text}
          block1Image={content.block1Image}
          block2Title={content.block2Title}
          block2Text={content.block2Text}
          block2Image={content.block2Image}
          block1ButtonHref={content.block1ButtonHref}
          block1ButtonText={content.block1ButtonText}
        />
        <CheckYourShippingBlock
          title={content.checkYourShippingBlockTitle}
          text={content.checkYourShippingBlockText}
          image={content.checkYourShippingBlockImage}
        />
        <NeedHelp text={content.needHelpText} buttonText={content.needHelpButtonText} />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    content: indexJson {
      pageSeoTitle
      heroTitleBlack
      heroTitleBlue
      inputPlaceHolder
      searchButtonText
      whereDoIFindTheTrackingCode
      block1Title
      block1Text
      block1Image {
        childImageSharp {
          gatsbyImageData(width: 1000)
        }
      }
      block1ButtonHref
      block1ButtonText
      block2Title
      block2Text
      block2Image {
        childImageSharp {
          gatsbyImageData(width: 1000)
        }
      }
      checkYourShippingBlockTitle
      checkYourShippingBlockText
      checkYourShippingBlockImage {
        absolutePath
        childImageSharp {
          gatsbyImageData(width: 1000)
        }
      }
      needHelpText
      needHelpButtonText
    }
  }
`;
