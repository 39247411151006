import React from 'react';
import { getButtonClass } from './shared';
import { IButtonProps } from './types';

export const Button: React.FC<IButtonProps> = ({
  children,
  color,
  dimension,
  className,
  secondary,
  onClick,
  ...buttonProps
}) => (
  // @ts-expect-error TOFIX
  <button onClick={onClick} className={getButtonClass(color, dimension, secondary, className)} {...buttonProps}>
    {children}
  </button>
);
