import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export const DefaultTrackingMetadata = () => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t('tracking_page.pagemetadata.title')}</title>
      <meta property="og:site_name" content="Spedire.com" />
      <meta property="og:url" content="https://www.spedire.com" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://www.spedire.com/facebook.png" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="470" />
      <meta property="og:image:height" content="275" />
      <meta content={t('tracking_page.pagemetadata.description')} name="description" />
    </Helmet>
  );
};

export const TicketMetadata = () => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t('ticket.pagemetadata.title')}</title>
      <meta property="og:site_name" content="Spedire.com" />
      <meta property="og:url" content="https://www.spedire.com" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://www.spedire.com/facebook.png" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="470" />
      <meta property="og:image:height" content="275" />
      <meta content={t('tracking_page.pagemetadata.description')} name="description" />
    </Helmet>
  );
};
