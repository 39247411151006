import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { LinkButton } from '../../../components/button';
import { ContentContainer } from '../../../components/contentContainer';
import { Markdown } from '../../../components/markdown';

interface IHelpGridProps extends React.HTMLAttributes<HTMLDivElement> {
  block1Title: string;
  block1Text: string;
  block1Image: any;
  block1ButtonHref: string;
  block1ButtonText: string;
  block2Title: string;
  block2Text: string;
  block2Image: any;
}

export const HelpGrid: React.FC<IHelpGridProps> = ({
  block1Title,
  block1Text,
  block1Image,
  block1ButtonHref,
  block1ButtonText,
  block2Title,
  block2Text,
  block2Image,
  ...divProps
}) => (
  <div {...divProps}>
    <ContentContainer>
      <div className="grid grid-cols-1 md:grid-cols-2 pb-16 md:pb-32">
        <div className="order-2 md:order-1 md:p-4 flex flex-col justify-center items-baseline">
          {/* @ts-expect-error TODO */}
          <GatsbyImage image={getImage(block1Image)} alt="spedizione" />
        </div>
        <div className="order-1 md:order-2 md:p-4 flex flex-col justify-center items-baseline">
          <div id="dove-trovo-il-codice-di-tracking" className="-top-20 md:-top-28 block relative"></div>
          <h1 className="title text-gray-900">{block1Title}</h1>
          <div className="prose text text-gray-500">
            <Markdown>{block1Text}</Markdown>
          </div>
          <LinkButton color="blue" secondary className="mt-2 mb-8" href={block1ButtonHref} dimension="xl">
            {block1ButtonText}
          </LinkButton>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 pb-16 md:pb-32">
        <div className="md:p-4 flex flex-col justify-center items-baseline">
          <h1 className="title text-gray-900">{block2Title}</h1>
          <div className="prose text text-gray-500">
            <Markdown>{block2Text}</Markdown>
          </div>
        </div>
        <div className="md:p-4 flex flex-col justify-center items-baseline">
          {/* @ts-expect-error TODO */}
          <GatsbyImage image={getImage(block2Image)} alt="destinatario" />
        </div>
      </div>
    </ContentContainer>
  </div>
);
